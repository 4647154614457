.header__heading-container {
    margin: 178px 0 0 200px;
}

@media screen and (max-width: 1599px) {
    .header__heading-container {
        margin: 130px 0 0 110px;
    }
}

@media screen and (max-width: 1279px) {
    .header__heading-container {
        margin: 60px 0 20px 0;
    }
}