.about__text-container {
    max-width: 751px;
    margin: 37px 0 134px 51px;
}

@media screen and (max-width: 1599px) {
    .about__text-container {
        display: block;
        max-width: 600px;
        margin: 37px 10px 134px 51px;
    }
}

@media screen and (max-width: 1279px) {
    .about__text-container {
        margin: 37px 0 134px 0;
    }
}

@media screen and (max-width: 719px) {
    .about__text-container {
        max-width: 280px;
        margin: 37px 10px 134px;
    }
}