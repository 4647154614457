.team__quote-bubble {
    margin: 58px 0 0;
    width: 492px;
    height: 332px;
    background-image: url("../../../images/bubble.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

@media screen and (max-width: 1919px) {
    .team__quote-bubble  {
        width: 390px;
    }
}

@media screen and (max-width: 1599px) {
    .team__quote-bubble  {
        width: 360px;
    }
}

@media screen and (max-width: 719px) {
    .team__quote-bubble  {
        width: 290px;
    }
}