.team__quote{
    display: block;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    max-width: 422px;
    padding: 45px 0 0;
}

@media screen and (max-width: 1919px) {
    .team__quote {
        max-width: 380px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1599px) {
    .team__quote {
        max-width: 330px;
        font-size: 12px;
    }
}

@media screen and (max-width: 719px) {
    .team__quote {
        max-width: 260px;
        font-size: 10px;
        padding: 25px 0 0;
    }
}