.header {
    background-image: url(../../images/stars.svg);
    background-repeat: no-repeat;
}

@media screen and (max-width: 1279px) {
    .header-main-container {
        flex-direction: column-reverse;
        align-items: center;
    }
}
