.navigation__heart-one {
    position: absolute;
    top: 35px;
    left: 27px;
}

.navigation__heart-two {
    position: absolute;
    top: 322px;
    left: 52px;
}

.navigation__heart-three {
    position: absolute;
    left: 0;
    bottom: 95px;
}

.navigation__sidearc {
    position: absolute;
    right: 0;
    top: 0;
}