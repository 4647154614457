.footer {
    background-color: #7D5BA6;
    color: white;
}

@media screen and (max-width: 719px) {
    .footer {
        padding-left: 10px;
    }
}

.footer__main-container {
    font-size: 30px;
    padding-top: 58px;
    margin: 0 0 77px 262px;
}

@media screen and (max-width: 1279px) {
    .footer__main-container {
        font-size: 24px;
        margin: 0 0 77px 150px;
    }
}

@media screen and (max-width: 719px) {
    .footer__main-container {
        margin: 0 auto 77px;
        flex-direction: column;
        align-items: flex-start;
        max-width: 320px;
    }
}

.footer__text-container {
    min-height: 195px;
}

.footer__main-text {
    margin: 0;
}

.footer__small {
    font-size: 14px;
    padding: 20px 0 22px;
    margin: 0;
    width: 100%;
    border-top: 1px solid white;
}

.footer__small-container {
    margin: 0 0 0 262px;
}

@media screen and (max-width: 1279px) {
    .footer__small-container {
        margin: 0 10px 0 150px;
    }
}

@media screen and (max-width: 719px) {
    .footer__small-container {
        flex-direction: column;
        margin: 0 auto 10px;
        align-items: flex-start;
        max-width: 320px;
    }
}

.footer__buttons {
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    text-decoration: none;
    color: white;
    margin-right: 5px;
}

.footer__buttons:hover {
    opacity: 0.8;
}

.footer__heart {
    margin: 0 298px 0 364px;
}

@media screen and (max-width: 1599px) {
    .footer__heart {
        margin: 0 130px 0 150px;
    }
}

@media screen and (max-width: 1279px) {
    .footer__heart {
        margin: 0 70px 0 100px;
    }
}

@media screen and (max-width: 719px) {
    .footer__heart {
        margin: 30px auto;
    }
}

.footer__name {
    margin: 0 210px 0 317px;
}

@media screen and (max-width: 1599px) {
    .footer__name {
        margin: 0 60px 0 80px;
    }
}

@media screen and (max-width: 719px) {
    .footer__name {
        margin: 30px 0;
    }
}