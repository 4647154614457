.container {
    background-color: rgba(232, 225, 215, 0.7);
    background-image: url("../../images/Noise & Texture.svg");
}

.page {
    margin: 0 auto;
    padding: 0;
    max-width: 1920px;
    width: 100%;
    font-family: Montserrat, sans-serif;
}

body {
    margin: 0;
}

@font-face {
    font-family: 'Comic CAT';
    src: url('../../fonts/Comic_CAT.otf') format('opentype'), url('../../fonts/Cat Comic.woff2') format('woff2'),
    url('../../fonts/Cat Comic.woff') format('woff'),
    url('../../fonts/Cat Comic.ttf') format('truetype');
}