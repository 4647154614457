.header__picture {
    margin: 86px 89px 92px 0;
}

@media screen and (max-width: 1599px) {
    .header__picture {
        width: 600px;
    }
}

@media screen and (max-width: 1279px) {
    .header__picture {
        margin: 86px 0 0 0;
    }
}

@media screen and (max-width: 719px) {
    .header__picture {
       width: 300px;
    }
}