.about__picture {
    margin: 24px 0 0 200px;
    width: 540px;
    height: 800px;
}

@media screen and (max-width: 1599px) {
    .about__picture {
        margin: 24px 0 0 120px;
        width: 480px;
        height: 740px;
    }
}

@media screen and (max-width: 1279px) {
    .about__picture {
        margin: 24px 0 0 0;
    }
}

@media screen and (max-width: 719px) {
    .about__picture {
        width: 280px;
        height: 420px;
        margin: 24px 10px 0;
    }
}