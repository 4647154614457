.header__second-heading {
    font-family: Comic CAT, sans-serif;
    text-align: center;
    font-size: 44px;
    font-weight: 400;
    color: rgba(125, 91, 166, 1);
    margin: 60px 0 106px 0;
}

@media screen and (max-width: 1599px) {
    .header__second-heading {
        font-size: 38px;
        margin: 40px 0 82px 0;
    }
}

@media screen and (max-width: 1279px) {
    .header__second-heading {
        margin: 40px 0 50px 0;
    }
}

@media screen and (max-width: 719px) {
    .header__second-heading {
        font-size: 22px;
    }
}