.header__nav {
    padding: 80px 0 0 196px;
}

@media screen and (max-width: 1279px) {
    .header__nav {
        padding: 80px 0 0 80px;
    }
}

@media screen and (max-width: 719px) {
    .header__nav {
        display: none;
    }
}