.navigation__picture {
    display: block;
    margin-right: 30px;
    z-index: 100;
    position: relative;
}

.navigation__picture:last-child {
    margin-right: 0;
}

.navigation__picture:hover {
    opacity: 0.8;
}


.navigation__image {

}

@media screen and (max-width: 1919px) {
    .navigation__image {
        width: 320px;
    }
}

@media screen and (max-width: 1599px) {
    .navigation__image {
        width: 260px;
    }
}

@media screen and (max-width: 1279px) {
    .navigation__picture {
        flex: 1 1 37%;
        margin:0;
    }
}