.team__heading {
    font-family: Comic CAT, sans-serif;
    font-size: 100px;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
    padding-top: 90px;
}

@media screen and (max-width: 719px) {
    .team__heading {
        font-size: 60px;
    }
}