.team__member{
    max-width: 490px;
    margin-right: 70px;
}

.team__member:last-child{
    margin-right: 0;
}

@media screen and (max-width: 1599px) {
    .team__member{
        margin-right: 30px;
    }
}

@media screen and (max-width: 1279px) {
    .team__member{
        margin-right: 0;
    }
}