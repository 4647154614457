.team__container {
   padding: 48px 150px 151px 160px;
   z-index: 100;
   position: relative;
}

@media screen and (max-width: 1919px) {
   .team__container {
      padding: 48px 110px 151px 110px;
   }
}

@media screen and (max-width: 1599px) {
   .team__container {
      padding: 48px 60px 151px;
   }
}

@media screen and (max-width: 1279px) {
   .team__container {
      flex-direction: column;
      align-items: center;
      padding: 48px 0 151px;
      margin: 0 10px;
   }
}