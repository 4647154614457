.header__big-button {
    font-family: Montserrat, sans-serif;
    width: 498px;
    height: 126px;
    font-size: 40px;
    text-align: center;
    background-color: rgba(255, 27, 28, 1);
    color: rgba(255, 250, 250, 1);
    border: 3px solid rgba(251, 54, 64, 1);
    border-radius: 74px;
    cursor: pointer;
    padding: 0;
}

.header__big-button:hover{
    opacity: 0.6;
}

.button-lines {
    position: absolute;
    top: -45px;
    right: -45px;
}

@media screen and (max-width: 719px) {
    .button-lines {
        display: none;
    }

    .header__big-button{
        width: 300px;
        height: 60px;
        font-size: 24px;
    }
}