.repertoire {
    position: relative;
    overflow: hidden;
}

.repertoire__violetMime {
    position: absolute;
    left: 202px;
}

@media screen and (max-width: 1919px) {
    .repertoire__violetMime {
        width: 630px;
    }
}

@media screen and (max-width: 1599px) {
    .repertoire__violetMime {
        width: 400px;
    }
}

@media screen and (max-width: 719px) {
    .repertoire__violetMime {
        width: 300px;
        left: 20px;
    }
}

.repertoire__main-header {
    font-family: "Comic Cat", sans-serif;
    color: #7D5BA6;
    font-size: 100px;
    margin: 226px 0 79px;
}

@media screen and (max-width: 719px) {
    .repertoire__main-header {
        margin-top: 250px;
        font-size: 50px;
    }
}

.repertoire__subtext {
    color: #7D5BA6;
    font-size: 22px;
    font-style: italic;
    margin: 0;
}

@media screen and (max-width: 719px) {
    .repertoire__subtext {
        font-size: 14px;
        margin-left: 20px;
    }
}

.repertoire__subtext:nth-child(2) {
    margin-left: 100px
}

@media screen and (max-width: 1279px) {
    .repertoire__subtext:nth-child(2) {
        margin-left: 0;
    }
}

@media screen and (max-width: 719px) {
    .repertoire__subtext:nth-child(2) {
        font-size: 14px;
        margin-left: 20px;
    }
}

.repertoire__subtext:nth-child(3) {
    margin: 28px 0 110px 35px
}

.repertoire__event {
    margin: 0 110px;
    max-width: 1700px;
    border-top: 2px solid #7D5BA6;
    border-bottom: 2px solid #7D5BA6;
}

@media screen and (max-width: 1279px) {
    .repertoire__event {
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media screen and (max-width: 719px) {
    .repertoire__event {
        margin: 0 20px;
    }
}

.repertoire__event-texts {
    max-width: 822px;
    margin: 102px 0 0 172px;
}

@media screen and (max-width: 1279px) {
    .repertoire__event-texts {
        margin: 0;
    }
}

.repertoire__event-header {
    margin: 0;
    font-size: 56px;
    font-weight: 600;
}

.repertoire__event-subheader {
    margin: 60px 0 50px;
    font-size: 30px;
    font-weight: 600;
}

.repertoire__event-text {
    font-size: 22px;
    margin: 0 0 30px;
}

.repertoire__event-text-bottom {
    margin-bottom: 70px;
}

.repertoire__event-price {
    border-top: 0.5px solid #7D5BA6;
    font-size: 22px;
    margin: 0 0 118px 172px;
    padding-top: 28px;
}

@media screen and (max-width: 719px) {
    .repertoire__event-price {
        margin: 0 0 20px;
    }
}

.repertoire__event-picture {
    margin: 82px 144px 180px 110px;
    object-fit: contain;
}

@media screen and (max-width: 1279px) {
    .repertoire__event-picture {
        margin: 82px 0 80px;
    }
}

@media screen and (max-width: 719px) {
    .repertoire__event-picture {
        width: 280px;
    }
}

.repertoire__acts {
    background-image: url("../../images/actsback.svg");
    z-index: 100;
}

.repertoire__acts-header {
    font-weight: 600;
    font-size: 56px;
    margin: 100px 0 0 282px;
}

@media screen and (max-width: 719px) {
    .repertoire__acts-header {
        font-size: 40px;
        margin: 100px auto 0;
    }
}

.repertoire__act {
    position: relative;
    max-width: 886px;
    margin: 60px 0 0 282px;
    z-index: 100;
}

@media screen and (max-width: 1279px) {
    .repertoire__act {
        position: relative;
        max-width: 886px;
        margin: 60px 110px 0;
        z-index: 100;
    }
}

@media screen and (max-width: 719px) {
    .repertoire__act {
        max-width: 886px;
        margin: 60px 20px 0;
        z-index: 100;
    }
}

.repertoire__act:nth-child(5) {
    margin-bottom: 100px;
}

.repertoire__act-header {
    font-weight: 300;
    font-size: 30px;
    margin: 36px 0 0 0;
    padding-bottom: 10px;
    border-bottom: solid 0.5px #FB3640;
}

.repertoire__act-text {
    font-weight: 300;
    font-size: 22px;
    margin: 10px 0 0 0;
}

.repertoire__event-second-picture {
    margin: 111px 117px 111px 10px;
    object-fit:contain;
}

@media screen and (max-width: 1599px) {
    .repertoire__event-second-picture {
        margin: 111px 40px 111px 10px;
        width: 550px;
        z-index: 100;
    }
}

.repertoire__drop-left-party {
    position: absolute;
    left: -110px;
    top: 0;
}

.repertoire__drop-right-party {
    position: absolute;
    right: -110px;
    top: -80px;
}

@media screen and (max-width: 719px) {
    .repertoire__drop-left-party {
        display: none;
    }

    .repertoire__drop-right-party {
        display: none;
    }
}

.repertoire__party-stars {
    position: absolute;
    left: 60px;
    bottom: 47px;
}

.repertoire__button {
    font-family: Montserrat, sans-serif;
    outline: none;
    text-decoration: none;
    display: block;
    width: 495px;
    height: 92px;
    font-size: 32px;
    text-align: center;
    background-color: rgba(255, 27, 28, 1);
    color: rgba(255, 250, 250, 1);
    border: 3px solid rgba(251, 54, 64, 1);
    border-radius: 74px;
    cursor: pointer;
    padding: 0;
    margin: 134px auto 101px;
}

@media screen and (max-width: 719px) {
    .repertoire__button  {
        width: 300px;
        font-size: 24px;
    }
}

.repertoire__button:hover {
    opacity: 0.6;
}

.repertoire__plays {
    background-image: url("../../images/playsback.svg");
}

.repertoire__repline-one {
    position: absolute;
    top: 150px;
    right: -90px;
}

.repertoire__repline-two {
    position: absolute;
    top: 300px;
    right: -60px;
}

.repertoire__drop-left {
    position: absolute;
    left: -110px;
    top: -130px;
}

.repertoire__drop-right {
    position: absolute;
    right: -110px;
    top: -120px;
}

@media screen and (max-width: 719px) {
    .repertoire__drop-left {
        display: none;
    }

    .repertoire__drop-right {
        display: none;
    }
}

.repertoire__welcome-stars {
    position: absolute;
    top: 120px;
    left: 750px;
}

.bottbutline-one {
    position: absolute;
    left: -60px;
    top: 80px;
}

.bottbutline-two {
    position: absolute;
    left: -80px;
    top: -70px;
}

.repertoire__link{
    text-decoration: none;
}

.repertoire__button-container {
    width: 495px;
    height: 92px;
    margin: 0 auto;
}

@media screen and (max-width: 719px) {
    .repertoire__button-container {
        width: 300px;
    }
}

.repertoire__acts-bg {
    background-image: url("../../images/side-clown.svg");
    background-size: initial;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right 15% top 136px;
}


.repertoire__acts-bg-boy {
    background-image: url("../../images/side-boy.svg");
    background-size: initial;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right 15% top 136px;
}

@media screen and (max-width: 1279px) {
    .repertoire__acts-bg {
        background-position: right top 20px;
        background-size: 200px;
    }

    .repertoire__acts-bg-boy {
        background-position: right top 20px;
        background-size: 200px;
    }
}