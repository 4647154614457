.reviews__heart-one {
    position: absolute;
    top: 95px;
    right: 543px;
}

.reviews__heart-two {
    position: absolute;
    top: 252px;
    right: 286px;
}

.reviews__heart-three {
    position: absolute;
    top: 348px;
    right: 565px;
}

.reviews__container {
    max-width: 1700px;
    margin: 73px auto 167px;
    border-top: 1px solid #7D5BA6;
}

.reviews__header {
    font-family: Comic CAT, sans-serif;
    font-size: 100px;
    font-style: italic;
    font-weight: 400;
    line-height: 115px;
    text-align: center;
    color: rgba(125, 91, 166, 1);
    margin: 233px 0 122px;
}

@media screen and (max-width: 1279px) {
    .reviews__header {
        font-size: 76px;
    }
}

.reviews__line-top {
    position: absolute;
    left: -60px;
    top: -60px;
}

.reviews__line-bot {
    position: absolute;
    left: -100px;
    top: 70px;
}

.reviews__review {
    width: 480px;
    height: 975px;
    background-color: rgba(125, 91, 166, 0.2);
    border: 3px solid rgba(125, 91, 166, 0.5);
    border-radius: 30px;
}

@media screen and (max-width: 1599px) {
    .reviews__review {
        max-width: 360px;
    }
}

@media screen and (max-width: 1279px) {
    .reviews__review {
        max-width: 230px;
    }
}

@media screen and (max-width: 719px) {
    .reviews__review {
        max-width: 280px;
        max-height: 750px;
    }
}

@media screen and (max-width: 419px) {
    .reviews__review {
        max-width: 230px;
        max-height: 750px;
    }
}

.slick-slide {
    margin: 0 55px 0 0;
}

@media screen and (max-width: 719px) {
    .slick-slide {
        margin: 0;
    }
}

.review__photo {
    margin-top: 85px;
}

@media screen and (max-width: 1279px) {
    .review__photo {
        margin-top: 50px;
        width: 210px;
    }
}

@media screen and (max-width: 719px) {
    .review__photo {
        width: 200px;
    }
}

.review__name {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    margin: 45px 0 0;
}

.review__job {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    margin: 10px 0 0;
}

.review__comment {
    font-size: 20px;
    text-align: center;
    margin: 50px 0 0;
}

@media screen and (max-width: 1279px) {
    .review__comment {
        font-size: 18px;
        text-align: center;
        margin: 30px 0 0;
    }
}

@media screen and (max-width: 719px) {
    .review__comment {
        font-size: 14px;
    }
}

.review__dot {
    margin: 50px 0 70px;
}

.slider-container {
    display: block;
    min-width: 0;
    width: 1400px;
    height: 981px;
    margin: 0 auto;
}

@media screen and (max-width: 1599px) {
    .slider-container {
        max-width: 1000px;
    }
}

@media screen and (max-width: 1279px) {
    .slider-container {
        max-width: 600px;
    }
}

@media screen and (max-width: 719px) {
    .slider-container {
        max-width: 300px;
        max-height: 750px;
    }
}

@media screen and (max-width: 419px) {
    .slider-container {
        max-width: 245px;
    }
}

.reviews__review-container {
    max-width: 390px;
}

@media screen and (max-width: 1599px) {
    .reviews__review-container {
        max-width: 300px;
    }
}

@media screen and (max-width: 1279px) {
    .reviews__review-container {
        max-width: 250px;
    }
}

@media screen and (max-width: 719px) {
    .reviews__review-container {
        max-width: 200px;
    }
}

.reviews__header-container {
    max-width: 300px;
    margin: 0 auto;
}
