.menu {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
    display: flex;
    font-family: Inter, Helvetica, sans-serif;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: right;
    z-index: 1000;
}

.menu_opened {
    visibility: visible;
    opacity: 1;
}

.menu__container {
    position: relative;
    width: 520px;
    background: rgba(125, 91, 166, 1);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    height: 100vh;
}

@media screen and (max-width: 719px) {
    .menu__container {
        width: 60%;
    }
}

.menu__close-button {
    position: absolute;
    left: 85%;
    top: 5%;
    background-image: url("../../images/close-button.svg");
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: none;
    appearance: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
}

.menu__close-button:hover {
    transition: ease-in;
    opacity: 0.6;
}

.menu__navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.menu__navigation_block-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 159px;
}

@media screen and (max-width: 719px) {
    .menu__navigation_block-top {
        margin-top: 144px;
    }
}

.menu__navigation__block-top-button {
    margin-bottom: 22px;
    appearance: none;
    background-color: transparent;
    background-position: center;
    padding: 0 0 4px;
    font-family: inherit;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
}

.menu__navigation__block-top-button:hover {
    transition: ease-in;
    border-bottom: solid 1px black;
    padding-bottom: 3px;
}

.navigation__menu-button {
    display: none;
}

@media screen and (max-width: 719px) {
    .navigation__menu-button {
        display: block;
    }
}