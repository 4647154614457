.about {
    color: white;
    position: relative;
    overflow: hidden;
}

.about__stars {
    position: absolute;
    bottom: 90px;
    left: 1094px;
}

.about__heart {
    position: absolute;
    left: 470px;
    bottom: 0;
}

.about__heart-one {
    position: absolute;
    top: 50px;
    left: 223px;
}

.about__heart-two {
    position: absolute;
    left:100px;
    top: 290px;
}

.about__container {

}

@media screen and (max-width: 1279px) {
    .about__container {
        flex-direction: column;
        align-items: center;
    }
}