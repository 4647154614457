.custom-arrow-prev {
    background-image: url("../../images/arrowleft.svg");
    width: 80px;
    height: 80px;
    background-size: cover;
    left: -90px;
}

.custom-arrow-prev:hover {
    background-image: url("../../images/arrowleft.svg");
    transition: ease-in;
    opacity: 0.6;
}

.custom-arrow-prev::before {
    visibility: hidden;
}

.custom-arrow-next {
    background-image: url("../../images/arrowright.svg");
    width: 80px;
    height: 80px;
    background-size: cover;
    right: -90px;
}

.custom-arrow-next:hover {
    background-image: url("../../images/arrowright.svg");
    transition: ease-in;
    opacity: 0.6;
}

.custom-arrow-next::before {
    visibility: hidden;
}

@media screen and (max-width: 859px) {
    .custom-arrow-prev {
        width: 40px;
        height: 40px;
        left: -45px;
    }

    .custom-arrow-next {
        width: 40px;
        height: 40px;
        right: -45px;
    }
}

@media screen and (max-width: 419px) {
    .custom-arrow-prev {
        width: 30px;
        height: 30px;
        left: -30px;
    }

    .custom-arrow-next {
        width: 30px;
        height: 30px;
        right: -25px;
    }
}