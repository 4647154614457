.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-space_between{
    justify-content: space-between;
}

.flex-align-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}