.header__nav-button {
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    border: none;
    background: none;
    margin: 0 85px 0 0;
    padding: 0;
}

.header__nav-button:hover {
    opacity: 0.6;
}

@media screen and (max-width: 1279px) {
    .header__nav-button {
        font-size: 24px;
        margin: 0 45px 0 0;
    }
}