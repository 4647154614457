.team {
    color: white;
    width: 100%;
}

.team__subheading {
    font-size: 22px;
    text-align: center;
    margin: 0;
}

.team__subheading-container {
    margin: 12px 6px 66px;
}