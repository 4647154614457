.navigation__container {
    padding: 132px 143px;
    overflow: hidden;
}

@media screen and (max-width: 1599px) {
    .navigation__container {
        padding: 110px 110px;
    }
}
@media screen and (max-width: 1279px) {
    .navigation__container {
        padding: 50px 50px;
    }
}

@media screen and (max-width: 1279px) {
    .flex-container {
        flex-wrap: wrap;
        align-items: center;
    }
}
