.header__main-heading {
    font-family: Comic CAT, sans-serif;
    font-size: 126px;
    font-weight: 400;
    color: rgba(125, 91, 166, 1);
    text-align: center;
    margin: 0;
}

@media screen and (max-width: 1599px) {
    .header__main-heading {
        font-size: 98px;
    }
}

@media screen and (max-width: 719px) {
    .header__main-heading {
        font-size: 56px;
    }
}