.form {
    padding: 132px 0 142px;
    margin: 0;
    overflow: hidden;
}

@media screen and (max-width: 1279px) {
    .form {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.form__main {
    margin: 133px 92px 78px 243px;
}

@media screen and (max-width: 1599px) {
    .form__main {
        margin: 133px 50px 78px 100px;
    }
}

@media screen and (max-width: 1279px) {
    .form__main {
        margin: 133px 140px 78px;
    }
}

.form__header {
    font-weight: bold;
    font-size: 26px;
    color: #7D5BA6;
    margin: 0 0 35px;
    text-align: center;
}

.form__subtext {
    font-size: 24px;
    margin: 0 0 65px;
    text-align: center;
}

.form__input {
    font-family: inherit;
    margin: 0;
    padding-bottom: 10px;
    border: none;
    border-bottom: solid 1px black;
    background: none;
    outline: none;
    font-size: 22px;
    min-width: 475px;
}

.form__input:first-child {
    margin-bottom: 40px;
}

.form__button {
    font-family: Montserrat, sans-serif;
    margin: 76px 0 35px;
    display: block;
    width: 250px;
    height: 70px;
    font-size: 26px;
    text-align: center;
    background-color: rgba(255, 27, 28, 1);
    color: rgba(255, 250, 250, 1);
    border: 3px solid rgba(251, 54, 64, 1);
    border-radius: 74px;
    cursor: pointer;
    padding: 0;
}

.form__button-violet {
    background-color: #7D5BA6;
    border: none;
}

.form__button:hover {
    opacity: 0.6;
}

.form__stars {
    position: absolute;
    left: 60px;
    top: 175px;
}

.form__mime {
    position: relative;
    object-fit: contain;
    z-index: 100;
}

@media screen and (max-width: 1609px) {
    .form__mime {
        width: 600px;
    }
}

@media screen and (max-width: 719px) {
    .form__mime {
        width: 300px;
    }
}

@media screen and (max-width: 719px) {
    .form__image {
        width: 300px;
    }

    .form__main {
        margin: 0;
    }

    .form__input {
        min-width: 300px
    }

    .form {
        margin: 0 10px;
        overflow: hidden;
    }
}
